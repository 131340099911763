import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Radio, Divider, Modal, Space, Input, Spin } from "antd";
import quiz_solution from "../../assets/svg-icons/quiz-solution.svg";
import "../../assets/css/common.css";
import Env from "../../utilities/services/Env";
import { toast } from "react-toastify";

const { TextArea } = Input;

const MocktestReportPopup = forwardRef(function MocktestReportPopup(
  props,
  ref
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeLoader, setActiveLoader] = useState(false);
  const [reportTypes, setReportTypes] = useState([]);
  const [reportDescription, setReportDescription] = useState("");
  const [selectedReportType, setSelectedReportType] = useState(1);
  const [contentProps, setContentProps] = useState(null);

  useImperativeHandle(ref, () => {
    return {
      showModal(contentProps) {
        setReportDescription("");
        setContentProps(contentProps);
        getReportTypes();
      },
    };
  });

  const getReportTypes = () => {
    const getReport = Env.get(props.envendpoint + `abuses/types`);
    getReport.then(
      (response) => {
        const reportTypes = [];
        const data = response.data.response.abuse_types;
        data.forEach((element) => {
          reportTypes.push({ id: element.id, name: element.content });
        });
        setActiveLoader(false);
        setIsModalVisible(true);
        setReportTypes(reportTypes);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsModalVisible(false);
  };

  const handleReportType = (e) => {
    setSelectedReportType(e.target.value);
  };

  const submitReport = (e) => {
    const requestBody = {
      report_type_id: contentProps.id,
      abuse_type_id: selectedReportType,
      description: reportDescription,
      report_type: `App\\Models\\MockTestQuestions`,
    };
    const postReport = Env.post(props.envendpoint + `abuses`, requestBody);
    postReport.then(
      (response) => {
        setIsModalVisible(false);
      },
      (error) => {
        console.error(error);
        error.response.data.message &&
          toast(error.response.data.message.description[0]);
      }
    );
  };

  return (
    <div>
      <Modal
        open={isModalVisible}
        footer={null}
        closable={false}
        centered={true}
        className="quiz-popup-report"
      >
        {activeLoader === false ? (
          <div
            className="quiz-popup-modal-body-content-instruction"
            style={{ padding: "25px" }}
          >
            <div className="quiz-popup-modal-body-report-container">
              <span className="quiz-popup-modal-body-report-container-icon">
                <img
                  src={quiz_solution}
                  alt="solution"
                  className="quiz-popup-modal-body-report-icon"
                />
              </span>
              <span className="quiz-popup-modal-body-report-container-title">
                Report
              </span>
            </div>
            <Divider className="quiz-popup-modal-body-report-divider" />
            <div className="quiz-popup-modal-body-report-content">
              <div className="quiz-popup-modal-inner-body-report-content">
                <Radio.Group
                  name="selectedReportType"
                  value={selectedReportType}
                  onChange={(e) => handleReportType(e)}
                >
                  <Space direction="vertical">
                    {reportTypes.map((item, index) => (
                      <Radio value={item.id} key={index}>
                        {item.name}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
                <div className="quiz-popup-modal-body-report-content">
                  <TextArea
                    rows={7}
                    placeholder="Enter Your Report"
                    name="reportDescription"
                    value={reportDescription}
                    onChange={(e) => setReportDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="quiz-popup-modal-body-report-footer">
                <span
                  className="quiz-popup-modal-body-report-footer-buttons"
                  onClick={handleCancel}
                >
                  CANCEL
                </span>
                <span
                  className="quiz-popup-modal-body-report-footer-buttons"
                  onClick={submitReport}
                >
                  REPORT
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Spin
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "40px",
            }}
          />
        )}
      </Modal>
    </div>
  );
});

export default MocktestReportPopup;
