import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Modal, Spin, Typography } from "antd";
import "../../assets/css/common.css";
import NetwrokError from "../../assets/svg-icons/Netwrok-error.svg";

const { Text } = Typography;

const MocktestErrorPopup = forwardRef(function MocktestErrorPopup(props, ref) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeLoader, setActiveLoader] = useState(false);
  const [type, seType] = useState();

  useImperativeHandle(ref, () => {
    return {
      showErrorModal(type) {
        props.handleclearQInterval();
        setIsModalVisible(true);
        setActiveLoader(false);
        seType(type);
      },
      closeErrorModal() {
        setIsModalVisible(false);
      },
    };
  });

  useEffect(() => {
    window.addEventListener("online", handleClick);
    return () => {
      window.removeEventListener("online", handleClick);
    };
  }, []);

  const handleClick = (event) => {
    handleRefresh(type);
  };

  const handleRefresh = (type) => {
    setActiveLoader(true);
    props.handleLoader();
    setIsModalVisible(false);
    switch (type) {
      case "userrecord":
        props.getMockUserRecord("userrecordsave");
        break;
      case "submit":
        props.postMockQuestionSubmit();
        break;
      case "leave":
        props.submitLeaveMocktest();
        break;
      default:
        window.location.reload();
    }
  };

  return (
    <div>
      <Modal
        open={isModalVisible}
        footer={null}
        closable={false}
        centered={true}
        maskClosable={false}
        className="mock-popup-leave"
      >
        {activeLoader ? (
          <div style={{ padding: "50px", textAlign: "center" }}>
            <Spin size="large" />
          </div>
        ) : (
          <div style={{ padding: "25px" }}>
            <div className="mock-popup-modal-body-center">
              <img src={NetwrokError} alt="NetwrokError" />
            </div>
            <div className="mock-popup-modal-body-center">
              <Text className="mock-popup-modal-body-leave">
                Network Error
              </Text>
            </div>
            <div className="mock-popup-modal-body-center">
              <Text className="mock-popup-modal-body-leave-content" strong>
                Something went wrong, click the refresh button to continue this
                mocktest further
              </Text>
            </div>
            <div style={{ textAlign: "center" }}>
              <span
                className="quiz-popup-modal-body-report-footer-buttons"
                onClick={() => {
                  handleRefresh(type);
                }}
              >
                Refresh
              </span>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});

export default MocktestErrorPopup;
