import React, { Component } from "react";
import { Button, Tabs, Radio, Card, Spin } from "antd";
import "../../../assets/css/quiz-result.css";
import { connect } from "react-redux";
import {
  mocktestStatusUpdate,
  mocktestReattempt,
} from "../../../reducers/action";
import $ from "jquery";
import { analytics } from "../../../firebase-config";
import { logEvent } from "firebase/analytics";
import LineChart from "../../../components/Mocktest/ChartJS/LineChart";
import BarChart from "../../../components/Mocktest/ChartJS/BarChart";
import MocktestTable from "../../../components/Mocktest/Tables/Table";
import Env from "../../../utilities/services/Env";
import _ from "lodash";
import MocktestPerformance from "../../../components/Mocktest/MocktestPerformance";

const { TabPane } = Tabs;

const overallColumns = [
  { title: "Section", dataIndex: "sectionName", key: "sectionName" },
  { title: "Questions", dataIndex: "question", key: "question" },
  { title: "Attempt", dataIndex: "attempted", key: "attempted" },
  { title: "Accurancy", dataIndex: "accurancy", key: "accurancy" },
  { title: "Time", dataIndex: "time", key: "time" },
  { title: "Score", dataIndex: "score", key: "score" },
];

const sectioncolumns = [
  {
    title: "",
    dataIndex: "status",
    key: "status",
    onCell: (_, index) => {
      if (index % 2 === 0) {
        return {
          rowSpan: 2,
        };
      } else {
        return {
          rowSpan: 0,
        };
      }
    },
  },
  {
    title: "Person",
    dataIndex: "person",
    key: "person",
  },
  { title: "Count", dataIndex: "count", key: "count" },
  { title: "Time", dataIndex: "time", key: "time" },
];

class MocktestResult extends Component {
  constructor() {
    super();
    this.state = {
      results: [],
      activeTabIndex: 1,
      activesecTabIndex: 1,
      activesecTabIndexTwo: 1,
      activeLoader: true,
      sectionComparison: [],
      overallPerformance: [],
      sectionPerformance: [],
      overallScorePercentile: [],
      overall_section_analysis: [],
      score_distribution: [],
      sectionScoreDistribution: [],
      sectionStrengthWeekness: [],
      SectionScorePercentile: [],
    };
    this.panes = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(mocktestStatusUpdate(false, ""));
    logEvent(analytics, "select_content", {
      page_title: "Mock test Result",
    });
    this.getOverviewPerformance(this.props.match.params.mock_id);
    this.getSectiontab(this.props.mocktest.mocktest_section);
  }

  componentDidUpdate() {
    $("#comments-icon").click("click", function () {
      $("html, body").animate({
        scrollTop: $("#comments-block").position().top,
      });
    });
  }

  getOverviewPerformance = (mock_test_id) => {
    const getQuizData = Env.get(
      this.props.envendpoint +
        `mocktest/mocktestoverallperformance?mock_test_id=${mock_test_id}`
    );
    getQuizData.then(
      (response) => {
        const data = response.data.response;
        let section_analysis = [];
        data.overall_section_analysis.forEach((item, i) => {
          section_analysis.push({
            key: i,
            sectionName: item.section_name,
            question: item.section_question_count,
            attempted: item.total_attempt_count,
            skipped_count: item.skipped_count,
            accurancy: `${item.section_accuracy}%`,
            time:
              item.time_take_in_section_time < 60
                ? `${item.time_take_in_section_time} sec`
                : `${Math.floor(
                    (item.time_take_in_section_time % 3600) / 60
                  )} min`,
            score: item.section_mark,
          });
        });
        this.setState({
          overallPerformance: data.overall_performance,
          overall_section_analysis: section_analysis,
        });
        this.getScoreDistribution(this.props.match.params.mock_id);
        this.getScorePercentile(this.props.match.params.mock_id);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  getScorePercentile = (mock_test_id) => {
    const getQuizData = Env.get(
      this.props.envendpoint +
        `mocktest/mocktestscorevspercentileoverall/${mock_test_id}`
    );
    getQuizData.then(
      (response) => {
        const data = response.data.response;
        let labels = [];
        labels.push(data.percentile);
        let values = [];
        values.push(parseInt(data.score));
        this.setState({
          activeLoader: false,
          overallScorePercentile: {
            labels: labels,
            data: values,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  getScoreDistribution = (mock_test_id) => {
    const getQuizData = Env.get(
      this.props.envendpoint +
        `mocktest/mocktestscoredistributionoverall/${mock_test_id}`
    );
    getQuizData.then(
      (response) => {
        const data = response.data.response;
        const labels = Object.keys(data.score_distribution);
        const values = Object.values(data.score_distribution);
        this.setState({
          activeLoader: false,
          score_distribution: {
            labels: labels.reverse(),
            data: values.reverse(),
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  getAllSectionPerformance = async (sections) => {
    try {
      const apiRequests = sections.map((endpoint) =>
        Env.get(
          this.props.envendpoint +
            `mocktest/mocktestsectioncomparison?mock_test_id=${this.props.match.params.mock_id}&section_id=${endpoint.key}`
        )
      );
      const responses = await Promise.allSettled(apiRequests);
      const fetchedData = responses
        .filter((response) => response.status === "fulfilled")
        .map((elements) => elements.value.data.response);
      this.getSectionPerformance(fetchedData);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  getAllSectionScoreDistribution = async (sections) => {
    try {
      const apiRequests = sections.map((endpoint) =>
        Env.get(
          this.props.envendpoint +
            `mocktest/mocktestscoredistributionsection/${this.props.match.params.mock_id}?mock_test_section_id=${endpoint.key}`
        )
      );
      const responses = await Promise.allSettled(apiRequests);
      const fetchedData = responses
        .filter((response) => response.status === "fulfilled")
        .map((elements) => elements.value.data.response);
      this.getSectionScoreDistribution(fetchedData);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  getAllSectionStrengthWeekness = async (sections) => {
    try {
      const apiRequests = sections.map((endpoint) =>
        Env.get(
          this.props.envendpoint +
            `mocktest/mockteststrengthandweekness?mock_test_id=${this.props.match.params.mock_id}&mock_test_section_id=${endpoint.key}`
        )
      );
      const responses = await Promise.allSettled(apiRequests);
      const fetchedData = responses
        .filter((response) => response.status === "fulfilled")
        .map((elements) => elements.value.data.response);
      this.getSectionStrengthWeekness(fetchedData);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  getAllScorePercentile = async (sections) => {
    try {
      const apiRequests = sections.map((endpoint) =>
        Env.get(
          this.props.envendpoint +
            `mocktest/mocktestscorevspercentileoverall/${this.props.match.params.mock_id}?mock_test_section_id=${endpoint.key}`
        )
      );
      const responses = await Promise.allSettled(apiRequests);
      const fetchedData = responses
        .filter((response) => response.status === "fulfilled")
        .map((elements) => elements.value.data.response);
      this.getSectionScorePercentile(fetchedData);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  getSectionScorePercentile = (response) => {
    response.forEach((item, index) => {
      let SectionScorePercentile = this.state.SectionScorePercentile;
      _.assign(SectionScorePercentile, {
        [`_${this.panes[index].key}`]: {
          labels: item.percentile,
          data: item.score,
        },
      });
      this.setState({
        SectionScorePercentile: SectionScorePercentile,
      });
    });
  };

  toggleFullScreen() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  getSectiontab = (section) => {
    if (section) {
      section.sort(function (a, b) {
        return a.sort_order - b.sort_order;
      });
      section.forEach((item, index) => {
        let panes = {};
        _.assign(panes, {
          key: item.id,
          label: item.section_name,
        });
        index === 0 &&
          this.setState({
            activesecTabIndex: item.id,
            activesecTabIndexTwo: item.id,
          });
        this.panes.push(panes);
      });
      this.getAllSectionPerformance(this.panes);
      this.getAllSectionScoreDistribution(this.panes);
      this.getAllSectionStrengthWeekness(this.panes);
      this.getAllScorePercentile(this.panes);
    }
  };

  getSectionPerformance = (response) => {
    response.forEach((item, index) => {
      let section_analysis = [];
      let timeTemp = [];
      let avetimeTemp = [];
      let temp = Object.keys(item.section_analysis);
      let fTemp = temp.filter((key) => {
        if (
          !key.includes("id") &&
          !key.includes("d_at") &&
          !key.includes("section") &&
          !key.includes("_time")
        ) {
          return key;
        }
        if (key.includes("_time")) {
          if (key.includes("average")) {
            avetimeTemp.push(key);
          } else {
            timeTemp.push(key);
          }
        }
      });

      fTemp.forEach((ele, i) => {
        let status = ele.includes("attempt")
          ? "Total Attempt"
          : ele.includes("correct")
          ? "Correct Answer"
          : ele.includes("wrong")
          ? "Wrong Answer"
          : ele.includes("skipped")
          ? "Skipped Answer"
          : "Not Visited";
        let timeT = null;
        if (ele.includes("average")) {
          timeT = status.includes("Not Visited")
            ? "-"
            : item.section_analysis[`${avetimeTemp[i]}`]
            ? item.section_analysis[`${avetimeTemp[i]}`] < 60
              ? `${item.section_analysis[`${avetimeTemp[i]}`]} sec`
              : `${item.section_analysis[`${avetimeTemp[i]}`] / 60} min`
            : item.section_analysis[`${avetimeTemp[i]}`];
        } else {
          console.log("timeTemp", timeTemp);
          timeT = status.includes("Not Visited")
            ? "-"
            : item.section_analysis[`${timeTemp[i]}`]
            ? item.section_analysis[`${timeTemp[i]}`] < 60
              ? `${item.section_analysis[`${timeTemp[i]}`]} sec`
              : `${item.section_analysis[`${timeTemp[i]}`] / 60} min`
            : item.section_analysis[`${timeTemp[i]}`];
        }
        section_analysis.push({
          key: i,
          status: status,
          person: ele.includes("average") ? "Average" : "You",
          count: item.section_analysis[ele],
          time: timeT,
        });
      });
      section_analysis.sort((a, b) => {
        return a.status > b.status ? -1 : 1;
      });
      let sectionComparison = this.state.sectionComparison;
      _.assign(sectionComparison, {
        [`_${item.section_analysis.section_id}`]: {
          section_analysis: section_analysis,
          section_performance: item.section_performance,
        },
      });
      this.setState({
        sectionComparison: sectionComparison,
      });
    });
  };

  getSectionScoreDistribution = (response) => {
    response.forEach((item, index) => {
      const labels = Object.keys(item.score_distribution);
      const values = Object.values(item.score_distribution);
      let sectionScoreDistribution = this.state.sectionScoreDistribution;
      _.assign(sectionScoreDistribution, {
        [`_${this.panes[index].key}`]: {
          labels: labels.reverse(),
          data: values.reverse(),
        },
      });
      this.setState({
        sectionScoreDistribution: sectionScoreDistribution,
      });
    });
  };

  getSectionStrengthWeekness = (response) => {
    response.forEach((item, index) => {
      let temp = [];
      item.strength_and_weekness.forEach((strength, key) => {
        strength.difficulty_levels.forEach((levels, key) => {
          temp.push({
            key: key + 1,
            index: strength.difficulty_levels.length,
            topics: strength.topic_name,
            level: levels.difficulty_name,
            question: levels.questions,
            score: levels.score,
            time: levels.time,
            accurancy: levels.accuracy,
            strength: levels.strength,
          });
        });
      });
      let sectionStrengthWeekness = this.state.sectionStrengthWeekness;
      _.assign(sectionStrengthWeekness, {
        [`_${this.panes[index].key}`]: temp,
      });
      this.setState({
        sectionStrengthWeekness: sectionStrengthWeekness,
      });
    });
  };

  display = () => {
    return (
      <Card
        bordered={true}
        style={{
          boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
          width: "100%",
          padding: "20px",
          margin: "0px 0px 30px 0px",
        }}
      >
        <div
          style={{
            padding: "0px 50px",
          }}
        >
          <MocktestPerformance
            performance={
              this.state.sectionComparison[`_${this.state.activesecTabIndex}`]
                .section_performance
            }
          />
          <div className="mocktest-section-analysis-table">
            <MocktestTable
              columns={sectioncolumns}
              dataSource={
                this.state.sectionComparison[`_${this.state.activesecTabIndex}`]
                  .section_analysis
              }
            />
          </div>
        </div>
      </Card>
    );
  };

  dispalyTwo = () => {
    const sectionStrengthWeekness = this.state.sectionStrengthWeekness;
    const Strengthcolumns = [
      {
        title: "Topics",
        dataIndex: "topics",
        onCell: (_, index) => {
          if (index % _.index === 0) {
            return {
              rowSpan: _.index,
            };
          } else {
            return {
              rowSpan: 0,
            };
          }
        },
      },
      {
        title: "Level",
        dataIndex: "level",
      },
      {
        title: "Questions",
        dataIndex: "questions",
        render: (e, questions) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "1px",
              gridAutoRows: "minmax(30px, auto)",
              justifyContent: "center",
              placeItems: "center",
            }}
          >
            {questions.question.map((tag) => {
              let color =
                tag.is_right_choice === 0
                  ? "gray"
                  : tag.is_right_choice === 1
                  ? "green"
                  : "red";
              return (
                <div
                  style={{
                    background: color,
                    border: "1px solid",
                    borderRadius: "50%",
                    height: "25px",
                    width: "25px",
                    fontSize: "14px",
                    color: "#fff",
                  }}
                >
                  {tag.question_no}
                </div>
              );
            })}
          </div>
        ),
      },
      {
        title: "Score",
        dataIndex: "score",
      },
      {
        title: "Time",
        dataIndex: "time",
      },
      {
        title: "Accuracy",
        dataIndex: "accurancy",
      },
      {
        title: "Strength",
        dataIndex: "strength",
      },
    ];
    return (
      <Card
        bordered={true}
        style={{
          boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
          width: "100%",
          padding: "45px 40px",
          margin: "0px 0px 30px 0px",
        }}
      >
        <MocktestTable
          columns={Strengthcolumns}
          dataSource={
            sectionStrengthWeekness[`_${this.state.activesecTabIndexTwo}`]
          }
        />
      </Card>
    );
  };

  render() {
    return (
      <>
        {this.state.activeLoader ? (
          <Spin className="app-spinner" size="large" />
        ) : (
          <div className="mocktest-analysis">
            <Tabs
              activeKey={this.state.activeTabIndex.toString()}
              onChange={(e) => {
                this.setState({ activeTabIndex: e });
              }}
              tabBarExtraContent={
                <Button
                  className="mocktest-analysis-solution-button"
                  type="primary"
                  block
                  size="large"
                  onClick={() => {
                    this.props.dispatch(mocktestStatusUpdate(true, "solution"));
                    this.props.dispatch(mocktestReattempt(false, ""));
                    this.props.navigate(
                      `/course-details/${this.props.match.params.id}/mocktest/${this.props.match.params.mock_id}`
                    );
                  }}
                >
                  View Solution
                </Button>
              }
            >
              <TabPane tab="Overview" key="1">
                <div className="mocktest-analysis-TabPane-boby">
                  <Card
                    bordered={true}
                    style={{
                      boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
                      width: "100%",
                      padding: "20px",
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <div className="mocktest-tab-menu-title">
                      Overall Performance
                    </div>
                    <div className="mocktest-tab-menu-body">
                      <MocktestPerformance
                        performance={this.state.overallPerformance}
                      />
                      <div style={{ padding: "20px 0px" }}>
                        <MocktestTable
                          columns={overallColumns}
                          dataSource={this.state.overall_section_analysis}
                        />
                      </div>
                    </div>
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
                      width: "100%",
                      padding: "20px",
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <div className="mocktest-tab-menu-title">
                      Percentile vs Score
                    </div>
                    <LineChart dataSet={this.state.overallScorePercentile} />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
                      width: "100%",
                      padding: "20px",
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <div className="mocktest-tab-menu-title">
                      Score Distribution
                    </div>
                    <BarChart dataSet={this.state.score_distribution} />
                  </Card>
                </div>
              </TabPane>
              <TabPane tab="Section Comparison" key="2">
                <div className="mocktest-analysis-TabPane-boby">
                  <div className="mocktest-section-analysis-tab">
                    <div style={{ background: "#fff" }}>
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({ activesecTabIndex: e.target.value });
                        }}
                        value={this.state.activesecTabIndex}
                        name="selectedTag"
                      >
                        {this.panes.map((item, index) => (
                          <Radio.Button
                            className="mocktest-toggle-buttons"
                            value={item.key}
                            key={item.key}
                          >
                            {item.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                    {this.display()}
                  </div>
                  <Card
                    bordered={true}
                    style={{
                      boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
                      width: "100%",
                      padding: "20px",
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <div className="mocktest-tab-menu-title">
                      Percentile vs Score
                    </div>
                    <LineChart
                      dataSet={
                        this.state.SectionScorePercentile[
                          `_${this.state.activesecTabIndex}`
                        ]
                      }
                    />
                  </Card>
                  <Card
                    bordered={true}
                    style={{
                      boxShadow: "6px 0px 18px rgba(90, 114, 200, 0.06)",
                      width: "100%",
                      padding: "20px",
                      margin: "0px 0px 30px 0px",
                    }}
                  >
                    <div className="mocktest-tab-menu-title">
                      Score Distribution
                    </div>
                    <BarChart
                      dataSet={
                        this.state.sectionScoreDistribution[
                          `_${this.state.activesecTabIndex}`
                        ]
                      }
                    />
                  </Card>
                </div>
              </TabPane>
              <TabPane tab="Strength and Weakness" key="3">
                <div className="mocktest-analysis-TabPane-boby">
                  <div className="mocktest-section-analysis-tab">
                    <div style={{ background: "#fff" }}>
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({
                            activesecTabIndexTwo: e.target.value,
                          });
                        }}
                        value={this.state.activesecTabIndexTwo}
                        name="selectedTag"
                      >
                        {this.panes.map((item, index) => (
                          <Radio.Button
                            className="mocktest-toggle-buttons"
                            value={item.key}
                            key={item.key}
                          >
                            {item.label}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </div>
                    {this.dispalyTwo()}
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        )}
      </>
    );
  }
}

export default connect((state) => {
  return {
    mocktest_solution: state.mocktest_solution,
    current_course: state.current_course,
    envendpoint: state.envendpoint,
    profile_update: state.profile_update,
  };
})(MocktestResult);
