import React from "react";
import Auth from "./Auth";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...props }) => {
  const is_new_user = useSelector((state) => state.is_new_user);

  const isAllowed = Auth.isAuthenticated();
  return isAllowed && is_new_user === false ? (
    <Navigate to="/home-feed" />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
