import React, { useState } from "react";
import { Col, Row, Avatar, Button, Checkbox, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../../assets/css/mocktestInstruction.css";
import StorageConfiguration from "../../utilities/services/StorageConfiguration";
import { profileImageUrl } from "../../utilities/services/Env";
import { useEffect } from "react";
import { toast } from "react-toastify";

const MocktestInstruction = (props) => {
  const [languageList, setLanguageList] = useState({});
  const [showNext, setShowNext] = useState(true);
  const [isdeclaration, setIsdeclaration] = useState(false);
  const [language, setLanguage] = useState({
    id: null,
    language: "",
    name: "",
  });

  useEffect(() => {
    getLanguageList(props.languageList);
  }, [props.languageList]);

  const getLanguageList = (data) => {
    if (data.length > 1) {
      setLanguageList(data);
    } else {
      setLanguageList(data);
      setLanguage({
        id: data[0].id,
        language: data[0].language,
        name: data[0].name,
      });
    }
  };

  const handleLanguageChange = (value) => {
    let language = {};
    languageList.filter((item) => {
      if (item.id === value) {
        language = { language: item.language, name: item.name };
      }
    });
    setLanguage({
      id: value,
      language: language.language,
      name: language.name,
    });
  };

  const handleStart = () => {
    if (languageList.length > 1) {
      language.id !== null
        ? props.handleStart(1, language, languageList)
        : toast("Pls select your default language");
    } else {
      props.handleStart(1, language, languageList);
    }
  };

  return (
    <div className="mocktest-instructionall-main mocktest-main-content">
      <Row align="middle" className="row-header">
        <Col
          xs={24}
          sm={16}
          md={18}
          lg={18}
          xl={20}
          xxl={20}
          className="title-column"
          style={{
            padding: "0px 5px",
          }}
        >
          {showNext ? (
            <>
              <div className="mock-general-instruction">
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.Instruction.instruction1_text,
                  }}
                  style={{ fontSize: "15px", padding: "10px" }}
                ></div>
              </div>
              <div className="mock-general-footer mock-general-flex-row">
                {props.showInstructions ? (
                  <>
                    <div>
                      <Button
                        type="button"
                        onClick={() => props.handleMockInfo()}
                        className="next-doubts-btn"
                      >
                        Back
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        type="link"
                        onClick={() => props.navigate(-1)}
                        style={{
                          color: "#3C4852",
                          fontWeight: "800",
                          marginTop: "5px",
                        }}
                      >
                        <ArrowLeftOutlined />
                        Go back
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="button"
                        onClick={() => {
                          setShowNext(false);
                        }}
                        className="next-doubts-btn"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mock-test-instruction">
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  <h2>{props.mocktest.title}</h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.Instruction.instruction2_text,
                  }}
                  style={{ fontSize: "16px", padding: "10px" }}
                ></div>
              </div>
              <div className="mock-general-footer">
                <div
                  style={{
                    background: "#ffff",
                    padding: "10px",
                    border: "1px solid #d9d9d9",
                    marginTop: "0px",
                  }}
                >
                  <div
                    style={{
                      background: "#ffff",
                      padding: "10px",
                    }}
                  >
                    <h3 className="language-choose">
                      Choose your default language:{" "}
                      <Select
                        defaultValue={language.id}
                        disabled={languageList.length === 1}
                        style={{
                          width: 100,
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                        placeholder="Select"
                        onChange={handleLanguageChange}
                        options={languageList.map((province) => ({
                          label: province.name,
                          value: province.id,
                        }))}
                      />
                    </h3>
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                      }}
                    >
                      Please note all questions will appear in your default
                      language. This language can be changed for a particular
                      question later on.
                    </div>
                  </div>
                  <div
                    style={{
                      background: "#ffff",
                      padding: "10px",
                      marginTop: "0px",
                    }}
                  >
                    <h3
                      className="title-description"
                      style={{ marginTop: "0px" }}
                    >
                      Declaration:
                    </h3>
                    <div className="declaration">
                      <Checkbox
                        onChange={(e) => setIsdeclaration(e.target.checked)}
                      >
                        I have read and understood the instructions. All
                        computer hardware allotted to me are in proper working
                        condition. I declare that I am not in possession of /
                        not wearing / not carrying any prohibited gadget like
                        mobile phone, Bluetooth devices etc. /any prohibited
                        material with me into the Examination Hall.I agree that
                        in case of not adhering to the instructions, I shall be
                        liable to be debarred from this Test and/or to
                        disciplinary action, which may include ban from future
                        Tests / Examinations
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    background: "#ffff",
                    padding: "15px",
                    borderTop: "1px solid #d9d9d9",
                    marginTop: "0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Button
                      type="button"
                      onClick={() => {
                        setShowNext(true);
                      }}
                      className="previous-btn"
                    >
                      Previous
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="button"
                      disabled={!isdeclaration}
                      onClick={() => handleStart()}
                      className="previous-btn"
                    >
                      I'm ready to begin
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Col>
        <Col
          xs={0}
          sm={8}
          md={6}
          lg={6}
          xl={4}
          xxl={4}
          className="icon-column"
          style={{ textAlign: "center", background: "#f5f8fa" }}
        >
          <div style={{ textAlign: "center" }}>
            {/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(props.profile_image) &&
            !props.profile_image.includes("data") &&
            !props.profile_image.includes("prtner") ? (
              <Avatar
                style={{
                  borderRadius: "90px",
                }}
                size={145}
                src={
                  profileImageUrl +
                  (props.profile_image === null
                    ? StorageConfiguration.sessionGetItem("profile_image")
                    : props.profile_image)
                }
              />
            ) : (
              <Avatar
                style={{
                  color: "#E0F3FF",
                  background: "#0B649D",
                  fontSize: "90px",
                }}
                size={145}
              >
                {props.profile_update.first_name !== undefined &&
                  props.profile_update.first_name.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </div>
          <div
            style={{
              flex: 2,
              padding: "10px 24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "18px",
            }}
          >
            <div style={{ color: "#0B649D", fontWeight: 600 }}>
              {props.profile_update.user_name === null
                ? StorageConfiguration.sessionGetItem("user_name")
                : props.profile_update.user_name}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MocktestInstruction;
