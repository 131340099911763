import React from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import Auth from "./Auth";
import AppFooter from "../components/layouts/AppFooter";
import AppHeader from "../components/layouts/AppHeader";
import { Layout } from "antd";

const { Content } = Layout;
function PrivateRoute(props) {
  const { component: Component, ...path } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return !Auth.isAuthenticated() ? (
    <Navigate to="/login" />
  ) : (
    <>
      {path.path === "/course-details/:id/quiz/:quiz_id" ||
      path.path === "/course-details/:id/mocktest/:mock_id" ||
      path.path === "/course-details/:id/mocktest/:mock_id/result" ||
      path.path === "/my-questions/details" ? (
        <Outlet />
      ) : (
        <div>
          <Layout className="main-layout">
            <AppHeader {...props} navigate={navigate} location={location} />
            <Layout style={{ background: "transparent" }}>
              <Content>
                <Outlet />
              </Content>
            </Layout>
            <AppFooter />
          </Layout>
        </div>
      )}
    </>
  );
}
export default PrivateRoute;
