import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "../assets/css/layout.css";
// Import your pages/components
import AllCourses from "../pages/AllCourses";
import Home from "../pages/HomeFeed";
import MyCourse from "../pages/MyCourse";
import CourseDetails from "../pages/CourseDetails";
import ArticleDetails from "../pages/CourseDetails/articles/ArticleDetails";
import QuizQuestions from "../pages/CourseDetails/quiz/QuizQuestions";
import QuizResult from "../pages/CourseDetails/quiz/QuizResult";
import EbookDetails from "../pages/CourseDetails/ebooks/EbookDetails";
import VideoDetails from "../pages/CourseDetails/videos/VideoDetails";
import MocktestView from "../pages/CourseDetails/mocktest/MocktestView";
import MyNotes from "../pages/MyNotes";
import MyQuestions from "../pages/MyQuestions";
import Settings from "../pages/Settings";
import PrivateRoute from "./PrivateRoute";
import Profile from "../pages/Profile";
import Preference from "../pages/Preference";
import Loginpage from "../pages/Authentication/Login";
import Ebooks from "../pages/MyEbooks";
import Coins from "../pages/MyCoins";
import MyPoints from "../pages/Points/MyPoints";
import AboutUs from "../pages/AboutUs";
import Authors from "../pages/Authors";
import FAQ from "../pages/FAQ";
import TermsConditions from "../pages/TermsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Careers from "../pages/Careers";
import PageNotFound from "../pages/PageNotFound";
import MyDoubts from "../pages/MyDoubts";
import MyQuestionDetails from "../pages/MyQuestions/QuestionDetails";
import PublicRoute from "./PublicRoute";
import { envEndpoint, envUpdate, envRemoteConfig } from "../reducers/action";
import { useDispatch, useSelector } from "react-redux";
import MyPurchase from "../pages/MyPurchase";
import { fetchAndActivate, getAll } from "@firebase/remote-config";
import { remoteConfig } from "../firebase-config";
import HelpCenter from "../pages/HelpCenter/HelpCenter";
import RefundPolicies from "../pages/RefundPolicies/RefundPolicies";
import Env from "../utilities/services/Env";
import NoInternet from "../pages/NoInternet";
import InviteFriends from "../pages/InviteFriends";
import ErrorBoundary from "./ErrorBoundary";
import CommonWrapper from "./RouterWrappedComponent";
// Import your actions and any necessary Redux setup

function AppRoute() {
  const dispatch = useDispatch();
  const envendpoint = useSelector((state) => state.envendpoint);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      getFirebaseData();
    } else {
      getGeneralSettings();
    }
  }, []);

  const getFirebaseData = () => {
    let firebaseData = [];
    let link = [];
    fetchAndActivate(remoteConfig).then(
      (_res) => {
        const base_url = getAll(remoteConfig);
        Object.entries(base_url).forEach(([key, value]) => {
          firebaseData.push({ key: key, value: value._value });
          link[key] = value._value;
        });
        console.log("link.base_url", link.base_url.concat("/"));
        dispatch(envEndpoint(link.base_url.concat("/")));
        dispatch(envRemoteConfig(link));
        getGeneralSettings();
        return firebaseData;
      },
      (err) => {
        console.log("error >>>>>", err);
      }
    );
  };

  const getGeneralSettings = () => {
    const getEnv = Env.get(envendpoint + `settings/general_settings_web`);
    getEnv.then((response) => {
      let data = response.data.response.data;
      data = window.atob(window.atob(data));
      data = JSON.parse(data).settings;
      dispatch(envUpdate(data));
    });
  };

  return (
    <div>
      {/* <ErrorBoundary> */}
        <Router>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route
                path="/"
                element={
                  <CommonWrapper path="/">
                    <Loginpage />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PublicRoute />}>
              <Route
                path="/login"
                element={
                  <CommonWrapper path="/login">
                    <Loginpage />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/home-feed"
                element={
                  <CommonWrapper path="/home-feed">
                    <Home />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-courses"
                element={
                  <CommonWrapper path="/my-courses">
                    <MyCourse />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/all-courses"
                element={
                  <CommonWrapper path="/all-courses">
                    <AllCourses />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/course-details/:id"
                element={
                  <CommonWrapper path="/course-details/:id">
                    <CourseDetails />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/course-details/:id/article/:article_id"
                element={
                  <CommonWrapper path="/course-details/:id/article/:article_id">
                    <ArticleDetails />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route
              element={
                <PrivateRoute path="/course-details/:id/quiz/:quiz_id" />
              }
            >
              <Route
                path="/course-details/:id/quiz/:quiz_id"
                element={
                  <CommonWrapper path="/course-details/:id/quiz/:quiz_id">
                    <QuizQuestions />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/course-details/:id/quiz/:quiz_id/result"
                element={
                  <CommonWrapper path="/course-details/:id/quiz/:quiz_id/result">
                    <QuizResult />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/course-details/:id/ebook/:ebook_id"
                element={
                  <CommonWrapper path="/course-details/:id/ebook/:ebook_id">
                    <EbookDetails />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/course-details/:id/video/:video_id"
                element={
                  <CommonWrapper path="/course-details/:id/video/:video_id">
                    <VideoDetails />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route
              element={
                <PrivateRoute path="/course-details/:id/mocktest/:mock_id" />
              }
            >
              <Route
                path="/course-details/:id/mocktest/:mock_id"
                element={
                  <CommonWrapper path="/course-details/:id/mocktest/:mock_id">
                    <MocktestView />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route
              element={
                <PrivateRoute path="/course-details/:id/mocktest/:mock_id" />
              }
            >
              <Route
                path="/course-details/:id/mocktest/:mock_id/result"
                element={
                  <CommonWrapper path="/course-details/:id/mocktest/:mock_id/result">
                    <MocktestView />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-notes"
                element={
                  <CommonWrapper path="/my-notes">
                    <MyNotes />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute path="/my-questions" />}>
              <Route
                path="/my-questions"
                element={
                  <CommonWrapper path="/my-questions">
                    <MyQuestions />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute path="/my-questions/details" />}>
              <Route
                path="/my-questions/details"
                element={
                  <CommonWrapper path="/my-questions/details">
                    <MyQuestionDetails />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/doubts"
                element={
                  <CommonWrapper path="/doubts">
                    <MyDoubts />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/doubts/:id"
                element={
                  <CommonWrapper path="/doubts/:id">
                    <MyDoubts />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/settings"
                element={
                  <CommonWrapper path="/settings">
                    <Settings />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/help-center"
                element={
                  <CommonWrapper path="/help-center">
                    <HelpCenter />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/refund-policies"
                element={
                  <CommonWrapper path="/refund-policies">
                    <RefundPolicies />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-ebooks"
                element={
                  <CommonWrapper path="/my-ebooks">
                    <Ebooks />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-coins"
                element={
                  <CommonWrapper path="/my-coins">
                    <Coins />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-points"
                element={
                  <CommonWrapper path="/my-points">
                    <MyPoints />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/about-us"
                element={
                  <CommonWrapper path="/about-us">
                    <AboutUs />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/profile"
                element={
                  <CommonWrapper path="/profile">
                    <Profile />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/preference"
                element={
                  <CommonWrapper path="/preference">
                    <Preference />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/authors"
                element={
                  <CommonWrapper path="/authors">
                    <Authors />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/faq"
                element={
                  <CommonWrapper path="/faq">
                    <FAQ />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/terms"
                element={
                  <CommonWrapper path="/terms">
                    <TermsConditions />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/privacy"
                element={
                  <CommonWrapper path="/privacy">
                    <PrivacyPolicy />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/careers"
                element={
                  <CommonWrapper path="/careers">
                    <Careers />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/my-order"
                element={
                  <CommonWrapper path="/my-order">
                    <MyPurchase />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/invite"
                element={
                  <CommonWrapper path="/invite">
                    <InviteFriends />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/not-found"
                element={
                  <CommonWrapper path="/not-found">
                    <PageNotFound />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/no-internet"
                element={
                  <CommonWrapper path="/no-internet">
                    <NoInternet />
                  </CommonWrapper>
                }
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Route>
          </Routes>
        </Router>
      {/* </ErrorBoundary> */}
    </div>
  );
}

export default AppRoute;
