import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Typography } from "antd";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
  responsive: true,
  animations: {
    tension: {
      duration: 2000,
      from: 1,
      to: 0,
      loop: true,
    },
  },
  plugins: {
    legend: {
      position: "right",
      display: false,
    },
    title: {
      display: false,
    },
  },
};

function BarChart(props) {
  const data = {
    labels: props.dataSet.labels,
    datasets: [
      {
        axis: "y",
        data: props.dataSet.data,
        fill: true,
        backgroundColor: ["green"],
        borderColor: ["green"],
        borderWidth: 3,
        barThickness: 7,
      },
    ],
  };

  return (
    <div>
      <Typography.Title
        level={5}
        style={{
          position: "absolute",
          top: "50%",
          transform: "rotate(270deg)",
          left: "0px",
        }}
      >
        Score Range
      </Typography.Title>
      <div style={{ padding: "25px" }}>
        <Bar options={options} data={data} />
      </div>
      <Typography.Title
        level={5}
        style={{
          textAlign: "center",
        }}
      >
        Users Percentage
      </Typography.Title>
    </div>
  );
}

export default BarChart;
