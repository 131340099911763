import React, { Component } from "react";
import { Card, Row, Col, Breadcrumb, Layout, FloatButton, Space } from "antd";
import "../../assets/css/common.css";
import quiz_start from "../../assets/svg-images/quiz-start-new.svg";
import Env from "../../utilities/services/Env";
import { NavLink } from "react-router-dom";
import {
  currentCourse,
  currentPageRouting,
  currentTabIndex,
  disablePreference,
  quizReattempt,
  quizResume,
  quizSolution,
  quizStart,
} from "../../reducers/action";
import { connect } from "react-redux";
import AppSidebar from "../../components/layouts/AppSidebar";
import { Content } from "antd/lib/layout/layout";
import NoRecords from "../../components/NoRecords";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeletons from "../../components/SkeletonsComponent";
import { analytics } from "../../firebase-config";
import { logEvent } from "firebase/analytics";
import { CommonService } from "../../utilities/services/Common";

class MyQuestions extends Component {
  constructor() {
    super();
    this.state = {
      myQuestions: [],
      TotalQuestion: "",
      activeLoader: true,
      activePage: 1,
    };
  }

  componentDidMount() {
    this.getMyQuestions("preference");
    this.props.dispatch(disablePreference(false));
    this.props.dispatch(quizSolution(false));
    this.props.dispatch(quizResume(false));
    this.props.dispatch(quizStart(false));
    this.props.dispatch(currentTabIndex(null));
    this.props.dispatch(currentPageRouting(null));
    this.props.dispatch(currentCourse(null));
    logEvent(analytics, "select_content", {
      page_title: "My Questions",
    });
  }

  getMyQuestions(type) {
    const getQuestionData = Env.get(
      this.props.envendpoint + `myquestions?page=${this.state.activePage}`
    );
    getQuestionData.then(
      (response) => {
        const data = response.data.response.myQuestions;
        setTimeout(() => {
          this.setState({
            myQuestions:
              type === "preference"
                ? data.data
                : this.state.myQuestions.concat(data.data),
            TotalQuestion: data.total,
            activeLoader: false,
          });
        }, 1000);
      },
      (error) => {
        CommonService.hendleError(error, this.props, "main");
      }
    );
  }

  handleQuestionCard(event, data) {
    this.props.dispatch(quizReattempt(true, data.quiz_question_id));
  }

  stringTrimmer(text) {
    if (text.length > 220) {
      return text.substr(0, 219) + "...";
    } else {
      return text;
    }
  }

  loadMore = () => {
    this.setState(
      (prev) => {
        return { activePage: prev.activePage + 1 };
      },
      () => this.getMyQuestions("paginate")
    );
  };

  render() {
    return (
      <Layout>
        <AppSidebar {...this.props} />
        <Layout>
          <Content>
            <div className="all-course-main main-content">
              <div style={{ padding: "10px" }}>
                <Breadcrumb items={[{ title: "My Questions" }]} />
              </div>
              <div>
                <div>
                  {this.state.myQuestions.length !== 0 ? (
                    <InfiniteScroll
                      dataLength={this.state.myQuestions.length}
                      next={this.loadMore}
                      hasMore={
                        this.state.myQuestions.length < this.state.TotalQuestion
                      }
                      loader={<Skeletons type={"course"} />}
                      scrollableTarget="scrollableDiv"
                    >
                      <Row>
                        {this.state.myQuestions.map((item, index) => (
                          <Col span={8} key={index} style={{ padding: "10px" }}>
                            <NavLink to={`/my-questions/details`}>
                              <Card
                                className="my-question-card-column"
                                onClick={(e) =>
                                  this.handleQuestionCard(e, item)
                                }
                                cover={
                                  <Space className="all-courses-card-image-attempt-start">
                                    <img alt="quiz_start" src={quiz_start} />
                                  </Space>
                                }
                                title={null}
                                style={{
                                  borderRadius: "0px",
                                  border: "0px solid lightgrey",
                                  cursor: "pointer",
                                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                  padding: "15px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "10px 0px",
                                    height: "160px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <div
                                    style={{ fontSize: "16px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.questions.question,
                                    }}
                                  />
                                </div>
                              </Card>
                            </NavLink>
                          </Col>
                        ))}
                      </Row>
                    </InfiniteScroll>
                  ) : this.state.activeLoader === true ? (
                    <Skeletons type={"course"} />
                  ) : (
                    <>
                      <NoRecords />
                    </>
                  )}
                  {this.state.activePage > 1 && <FloatButton.BackTop />}
                </div>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default connect((state) => {
  return {
    envendpoint: state.envendpoint,
  };
})(MyQuestions);
